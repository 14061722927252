import * as React from 'react';
import * as WUI from '@wartsila/ui-kit';
import './docrequest.styles.scss';

import get from 'lodash/get';

import { findKey, forEach, isEqual, values } from 'lodash';
import { OptionTypeBase, OptionsType } from 'react-select';
import { filterDictionary } from '../filters/filters.dictionary';
import { useMissingDocRequest } from './docrequest.hooks';
import { useFilterTypes } from '../filters/filters.hooks';
import {
  AssignmentsFilter,
  DocumentsFilter,
  FilterValue,
} from '../filters/filters.types';
import { FilterAsyncSingleSelect } from '../filters/filters.components';

const modalTitle = 'Request for missing document';
const referenceTypeHelperText = 'Select a product reference type (mandatory)';
const doctypeHelperText = 'Select a document subtype (mandatory)';
const productNumberHelperText =
  'Add a product number (mandatory - exceptions: Product Guide, Installation Manual)';
const userInputTitleHelperText = 'Add a title (mandatory)';
const userInputBodyHelperText = 'Please explain the request (optional)';

interface MissingDocsRequestProps {
  link?: boolean;
  filters?: { [key: string]: unknown };
  queryParams?: string;
  selectedAssignmentFilters?: Partial<AssignmentsFilter>;
  selectedDocumentTypes?: string[];
}

export const MissingDocRequest = ({
  link = false,
  filters = {},
  queryParams = '',
  selectedAssignmentFilters = { productReferenceType: [], equipment: [] },
  selectedDocumentTypes = [],
}: MissingDocsRequestProps): JSX.Element | null => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [doctype, setDoctype] = React.useState(
    selectedDocumentTypes?.[0] || ''
  ); // doctype looks like "subtype (maintype)"
  const [reftype, setReftype] = React.useState(
    selectedAssignmentFilters.productReferenceType || []
  );
  const [productNumber, setProductNumber] = React.useState(
    selectedAssignmentFilters.equipment || []
  );
  const [docTypeFilterOptions, setDocTypeFilterOptions] = React.useState<
    FilterValue[]
  >([]);
  const [title, setTitle] = React.useState('');
  const [comment, setComment] = React.useState('');

  const restrictedDocTypes = ['Product Guide', 'Installation Manual'];

  // when user opens modal, modal values must match filter values
  React.useEffect(() => {
    setReftype(selectedAssignmentFilters.productReferenceType || reftype);
    setProductNumber(selectedAssignmentFilters.equipment || productNumber);
    setDoctype(selectedDocumentTypes?.[0] || doctype);
    /* productNumber is not a mandatory field in the modal when 
    the doctype is 'Product Guide' or 'Installation Manual' */
    if (
      selectedDocumentTypes?.length > 0 &&
      restrictedDocTypes.includes(doctype)
    ) {
      setProductNumber([]);
    }
  }, [isOpen]);

  const generateDocTypeOptions = (
    filterTypes: DocumentsFilter
  ): FilterValue[] => {
    const result: FilterValue[] = [];

    filterTypes.forEach((parent) => {
      if (parent.children && parent.children.length > 0) {
        parent.children.forEach((child) => {
          result.push({
            value: child.value,
            label: `${child.label} (${parent.label})`,
          });
        });
      }
    });

    return result;
  };

  // extensions can include locations such as __DE that must be removed
  const removeReftypeLabelExtension = (label: string): string =>
    label.split('__')[0];

  const { data: filterTypes } = useFilterTypes();

  const handleDoctypeChange = (
    selection: OptionTypeBase | OptionsType<OptionTypeBase> | null
  ): void => {
    const selected = selection as FilterValue;
    setDoctype(selected.value);
  };

  React.useEffect(() => {
    if (filterTypes) {
      setDocTypeFilterOptions(
        generateDocTypeOptions(filterTypes?.documentTypes)
      );
    }
  }, [filterTypes]);

  const handleReftypeChange = (
    selection: OptionTypeBase | OptionsType<OptionTypeBase> | null
  ): void => {
    const selected = selection as FilterValue;
    setReftype([selected]);
  };

  const handleEquipmentNumberChange = (
    selection: OptionTypeBase | OptionsType<OptionTypeBase> | null
  ): void => {
    const selected = selection as FilterValue;
    setProductNumber([selected]);
  };
  /* missing documents used to be requested via email,
  keeping functionality here temporarily in case we need to revert
  const { mutate } = useMissingDocRequestViaEmail(); */
  const { mutate } = useMissingDocRequest();

  const formatFilters = (): string => {
    const formatedFilters: string[] = [];
    const formatedFiltersSubtypes: string[] = [];

    if (queryParams) {
      formatedFilters.push(`Query Parameters = ${queryParams}`);
    } else {
      forEach(filters, (filter, filterName) => {
        if (!filter) return;

        if (typeof filter === 'string') {
          formatedFilters.push(
            `${get(filterDictionary, filterName, filterName)} = ${filter}`
          );
          return;
        }

        if (typeof filter === 'boolean') {
          formatedFilters.push(get(filterDictionary, filterName, filterName));
          return;
        }

        if (typeof filter === 'object' || filter !== null) {
          // Exception for Spare Parts Catalogue (SPC) - this is a maintype without subtypes and should be displayed differently
          const exceptionMainTypeSPC = 'SPC';
          if (Object.keys(filter).includes(exceptionMainTypeSPC)) {
            formatedFilters.push(
              `Maintypes = ${get(filterDictionary, exceptionMainTypeSPC)}`
            );
          }
          forEach(values(filter), (subTypes: string[] | never) => {
            if (subTypes?.length) {
              const formattedSubTypes: string[] = [];
              forEach(
                subTypes,
                (subType) => subType && formattedSubTypes.push(subType)
              );

              const usedFilter =
                findKey(filter, (value) => isEqual(value, subTypes)) || '';
              forEach(formattedSubTypes, (subType) =>
                formatedFiltersSubtypes.push(
                  `${subType} (${get(
                    filterDictionary,
                    usedFilter,
                    usedFilter
                  )})`
                )
              );
            }
          });
        }
      });
    }
    if (formatedFiltersSubtypes.length) {
      formatedFilters.push(`Subtypes = ${formatedFiltersSubtypes.join(', ')}`);
    }
    return formatedFilters.join('\n');
  };

  const getMaintypeOfSubtype = (subtype: string): string => {
    /* Get 'Maintype' output from an input that looks like 'Subtype (Maintype)' */
    const match = docTypeFilterOptions
      .find((d) => d.label.includes(subtype))
      ?.label.match(/\(([^()]+)\)(?!.*\([^()]*\))/);
    return match ? match[1] : '';
  };

  return (
    <span>
      {link ? (
        <button
          type="button"
          className="footer__docrequest"
          onClick={() => setIsOpen(true)}>
          {modalTitle}
        </button>
      ) : (
        <WUI.Button
          variant={WUI.ButtonVariant.Warning}
          onClick={() => setIsOpen(true)}>
          {modalTitle}
        </WUI.Button>
      )}
      <WUI.Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <WUI.Modal.Header title={modalTitle} onClose={() => setIsOpen(false)} />
        <WUI.Modal.Content>
          <WUI.FormRow label={doctypeHelperText} className="flex-item">
            <WUI.AutoComplete
              options={docTypeFilterOptions}
              value={
                docTypeFilterOptions.filter((d) => d.value === doctype) ||
                undefined
              }
              onChange={handleDoctypeChange}
            />
          </WUI.FormRow>
          <WUI.FormRow label={referenceTypeHelperText} className="flex-item">
            <FilterAsyncSingleSelect
              dependentOn={['equipment']}
              filterType="productReferenceType"
              selection={{
                // we leave the selection like this now, but it should be centralised in a single state eventually
                productReferenceType: reftype,
                equipment: productNumber,
              }}
              onSelect={(selection: FilterValue) => {
                handleReftypeChange(selection);
              }}
              disableAutocomplete={
                !!selectedAssignmentFilters.productReferenceType?.length
              }
            />
          </WUI.FormRow>
          <WUI.FormRow label={productNumberHelperText} className="flex-item">
            <FilterAsyncSingleSelect
              dependentOn={['productReferenceType']}
              filterType="equipment"
              selection={{
                equipment: productNumber,
                productReferenceType: reftype,
              }}
              onSelect={(selection: FilterValue) => {
                handleEquipmentNumberChange(selection);
              }}
              disableAutocomplete={
                !!selectedAssignmentFilters.equipment?.length
              }
            />
          </WUI.FormRow>
          <WUI.FormRow label={userInputTitleHelperText}>
            <WUI.InputField
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </WUI.FormRow>
          <WUI.FormRow label={userInputBodyHelperText}>
            <WUI.TextArea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </WUI.FormRow>
          <WUI.Button
            disabled={
              !title ||
              !doctype ||
              !reftype.length ||
              // productNumber can be empty when the doctype is 'Product Guide' or 'Installation Manual'
              (!productNumber.length && !restrictedDocTypes.includes(doctype))
            }
            onClick={() => {
              mutate({
                title,
                comment,
                productReferenceType:
                  removeReftypeLabelExtension(reftype[0]?.value) || '',
                productNumber: productNumber[0]?.value || '',
                mainType: getMaintypeOfSubtype(doctype),
                subType: doctype,
                tkbFilters: formatFilters(),
              });
              setTitle('');
              setComment('');
              setIsOpen(false);
            }}>
            Send
          </WUI.Button>
        </WUI.Modal.Content>
      </WUI.Modal>
    </span>
  );
};
